<template>
  <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
    :columns="columns"
    :page-index="filter.pageIndex"
    :page-size="filter.pageSize"
    :total-rows="totalRows"
    :is-loading="isTableLoading"
    :sort-column="filter.sortColumn"
    :sort-order="filter.sortOrder"
    :draggable="false"
    :show-pagination="false"
    @pageChanged="onPageChange"
    @sort="sort">
    <tr v-for="(item, index) in activeItems"
      :key="item.quoteItemId"
      @click="highlightSelected(index, $event)">
      <td v-show="$userInfo.isSupportUser"
        class="has-vertical-middle has-text-right">
        <span>{{ item.lineNumber }}</span>
      </td>
      <td class="has-vertical-middle">
        <span>{{ item.itemNo }}</span>
      </td>
      <td class="has-vertical-middle">
        <span>{{ item.itemDesc }}</span>
      </td>
      <td class="has-vertical-middle is-content-width">
        <span>{{ item.subletType }}</span>
      </td>
      <!-- <td class="">{{item.vendorName}}</td> -->
      <td class="has-vertical-middle vendor-input">
        <span>{{ item.vendorName }}</span>
      </td>
      <td class="has-vertical-middle has-text-centered">
        <span class="icon has-text-info">
          <i class="mdi mdi-24px"
            :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
        </span>
      </td>
      <td class="has-vertical-middle has-text-centered is-content-width">0</td>
      <td class="has-vertical-middle has-text-centered is-content-width">0</td>
      <td class="has-vertical-middle has-text-right money-input">
        <span>{{ item.value | formatNumber($userInfo.locale) }}</span>
      </td>
      <td class="has-vertical-middle has-text-right">
        <span>{{ item.buyPrice | formatNumber($userInfo.locale) }}</span>
      </td>
      <td v-show="$userInfo.isSupportUser"
        class="has-vertical-middle has-text-centered is-content-width">{{ item.itemStatus }}</td>
      <td class="has-vertical-middle has-text-centered is-content-width">
        <a v-if="item.subletAuthorityDocument"
          class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
          data-tooltip="Click to view"
          @click="viewPdf(index)">
          <span class="icon">
            <i class="mdi mdi-24px"
              :class="[ item.subletAuthorityDocument !== null ? 'mdi-file-pdf' : '']" />
          </span>
        </a>
      </td>
    </tr>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large">
            <i class="mdi mdi-48px mdi-emoticon-sad" />
          </span>
          <p>Nothing</p>
        </div>
      </section>
    </template>
    <template slot="footer">
      <tr>
        <th class="has-text-right has-text-success"
          :colspan="totalColSpan">Totals</th>
        <th class="has-text-right has-text-success"
          :class="[ innerValue.sublTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ innerValue.sublTotal | formatCurrency($userInfo.locale) }}</th>
        <!-- <th colspan="2" /> -->
      </tr>
    </template>
  </bulma-table>
</template>

<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { SubletColumns } from '../columns'
import BulmaTable from '@/components/BulmaTable'
import _cloneDeep from 'lodash/cloneDeep'
import { PartTypes } from '@/enums'

export default {
  name: 'OverviewPart',
  filters: {
    formatPartType(value) {
      const index = Object.values(PartTypes).findIndex(item => item.value === value)
      return Object.keys(PartTypes)[index]
    }
  },
  components: {
    BulmaTable
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    itemType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerValue: this.value,
      isTableLoading: false,
      filter: {
        sortColumn: SubletColumns[1].name,
        sortOrder: SubletColumns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      }
    }
  },
  computed: {
    columns() {
      const c = _cloneDeep(SubletColumns)
      c[0].isVisible = this.$userInfo.isSupportUser
      c[c.length - 3].isVisible = this.$userInfo.isSupportUser
      c.splice(c.length - 1, 1)
      return c
    },
    activeItems() {
      return this.value.sublets.filter(i => !i.deleted)
    },
    totalRows() {
      return this.activeItems.length
    },
    totalColSpan() {
      let length = this.columns.length - 4
      if (this.$userInfo.isSupportUser) {
        length++
      }
      return length
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onPageChange() {},
    sort() {}
  }
}
</script>