<template>
  <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
    :columns="columns"
    :page-index="filter.pageIndex"
    :page-size="filter.pageSize"
    :total-rows="totalRows"
    :is-loading="isTableLoading"
    :sort-column="filter.sortColumn"
    :sort-order="filter.sortOrder"
    :draggable="false"
    :show-pagination="false"
    @pageChanged="onPageChange"
    @sort="sort">
    <tr v-for="(item, index) in activeItems"
      :key="item.quoteItemId"
      @click="highlightSelected(index, $event)">
      <td v-show="$userInfo.isSupportUser"
        class="has-vertical-middle has-text-right">
        <span>{{ item.lineNumber }}</span>
      </td>
      <td class="has-vertical-middle itemno-input">
        <span>{{ item.itemNo }}</span>
      </td>
      <td class="has-vertical-middle itemdesc-input">
        <span>{{ item.itemDesc }}</span>
      </td>
      <td class="has-vertical-middle has-text-centered">
        <span class="icon has-text-info">
          <i class="mdi mdi-24px"
            :class="[ item.blend ? 'mdi-check' : '' ]" />
        </span>
      </td>
      <td class="has-vertical-middle has-text-centered">
        <span class="icon has-text-info">
          <i class="mdi mdi-24px"
            :class="[ item.reportOnly ? 'mdi-check' : '' ]" />
        </span>
      </td>
      <td v-if="innerValue.quotingMethod === quotingMethodTypes.Hour"
        class="has-vertical-middle has-text-right">
        <span>{{ $filters.formatNumber(item.rate, $userInfo.locale) }}</span>
      </td>
      <td class="has-vertical-middle has-text-right">
        <span>{{ $filters.formatNumber(item.hourValue, $userInfo.locale) }}</span>
      </td>
      <td class="has-vertical-middle has-text-right">{{ $filters.formatNumber(item.dollarValue, $userInfo.locale) }}</td>
      <td class="has-vertical-middle has-text-centered">
        <span class="item-type-tag"
          :class="`tag is-${item.itemType.toLowerCase()}`">{{ item.itemType }}</span>
      </td>
      <td v-if="isNtar"
        class="has-vertical-middle has-text-right is-content-width">
        <span>{{ $filters.formatNumber(item.loadingPercent, $userInfo.locale, 0) }}%</span>
      </td>
      <td class="has-vertical-middle has-text-centered">
        <div v-if="item.presentAs">
          <div v-for="split in item.presentAs"
            :key="split.itemType">
            <div class="tags has-addons mb-0 present-as-tags">
              <span :class="`tag mb-1 is-${split.itemType.toLowerCase()}`">{{ split.itemType }}</span>
              <span class="tag is-dark mb-1">{{ split.percent }}%</span>
            </div>
          </div>
        </div>
      </td>
      <td v-show="$userInfo.isSupportUser"
        class="has-vertical-middle has-text-centered is-content-width">{{ item.itemStatus }}</td>
    </tr>
    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <span icon="icon is-large">
            <i class="mdi mdi-48px mdi-emoticon-sad" />
          </span>
          <p>Nothing</p>
        </div>
      </section>
    </template>
    <template slot="footer">
      <tr>
        <th class="has-text-right has-text-success"
          :colspan="totalColSpan">Totals</th>
        <th class="has-text-right has-text-success"
          :class="[ categoryTotal >= 0 ? 'has-text-success' : 'has-text-danger' ]">{{ categoryTotal | formatCurrency($userInfo.locale) }}</th>
        <th colspan="1" />
      </tr>
    </template>
  </bulma-table>
</template>

<script>
import { QuotingMethodTypes, LabourTimeTypes, ItemCategoryTypes } from '@/enums'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { LabourColumns } from '../columns'
import BulmaTable from '@/components/BulmaTable'
import _cloneDeep from 'lodash/cloneDeep'
import { QuoteLabourMixin } from '../mixins'

export default {
  name: 'OverviewLabour',
  filters: {},
  components: {
    BulmaTable
  },
  mixins: [NumberFiltersMixin, QuoteLabourMixin],
  props: {
    value: null,
    itemType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      innerValue: this.value,
      isTableLoading: false,
      filter: {
        sortColumn: LabourColumns[1].name,
        sortOrder: LabourColumns[1].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      }
    }
  },
  computed: {
    columns() {
      const c = _cloneDeep(LabourColumns)
      if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
        c[6].title = 'Amount'
        c.splice(5, 1)
      } else if (this.value.quotingMethod === QuotingMethodTypes.Hour) {
        c[6].title = LabourColumns[6].title
      }
      if (!this.isNtar) {
        c.splice(c.length - 4, 1)
      }
      c[0].isVisible = this.$userInfo.isSupportUser
      c[c.length - 2].isVisible = this.$userInfo.isSupportUser
      c.splice(c.length - 1, 1)
      return c
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    },
    isNtar() {
      return (
        this.innerValue.labourType === LabourTimeTypes.NTAR ||
        this.innerValue.labourType === LabourTimeTypes.LTAR ||
        this.innerValue.labourType === LabourTimeTypes.eMTA
      )
    },
    activeItems() {
      return this.value.labours.filter((i) => i.itemType === this.itemType && !i.deleted)
    },
    totalRows() {
      return this.activeItems.length
    },
    categoryTotal() {
      switch (this.itemType) {
        case ItemCategoryTypes.RR:
          return this.rrTotal
        case ItemCategoryTypes.RWA:
          return this.rwaTotal
        case ItemCategoryTypes.REP:
          return this.repairTotal
        case ItemCategoryTypes.PAINT:
          return this.paintTotal
      }
    },
    totalColSpan() {
      let length = this.isNtar ? this.columns.length - 5 : this.columns.length - 4
      if (this.$userInfo.isSupportUser) {
        length++
      }
      return length
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onPageChange() {},
    sort() {}
  }
}
</script>

<style lang="scss" scoped>
.item-type-tag {
  width: 52px;
}
.present-as-tags {
  display: inline-flex;
  .tag {
    font-size: 0.6rem !important;
  }
}
</style>